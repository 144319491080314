<template>
    <v-dialog
        v-model="showDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar class="dialog_toolbar" elevation="0">
                <div class="ma-auto ml-0">
                    <span>{{ $t('file') }}: {{documentTitle}}</span>
                </div>
                <div>
                    <v-icon @click="closeDialog" :title="$t('close')">mdi-close</v-icon>
                </div>
            </v-toolbar>
            <v-card-text style="height: 100vh;">
                <OnlyOfficeEditor
                    ref="onlyOfficeEditor"
                    :key="nameKey"
                    v-if="showDialog"
                    :showDialog="showDialog"
                    @closeDialog="closeDialog"
                    :file-type="fileType"
                    :document-key="documentKey"
                    :document-title="documentTitle"
                    :document-url="documentUrl"
                    :document-model="documentModel"
                    :editorId="nameKey"
                />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import OnlyOfficeEditor from "@/components/OnlyOfficeEditor.vue";

export default {
    name: "OnlyOfficeEditorDialog",
    props: {
        showDialog: {
            type: Boolean,
            default: false
        },
        fileType: {
            type: String,
            default: "docx",
        },
        documentKey: {
            type: String,
            required: true,
        },
        documentTitle: {
            type: String,
            default: "Test Document.docx",
        },
        documentUrl: {
            type: String,
            required: true,
        },
        documentModel: {
            type: String,
            required: true,
        },
        nameKey: {
            type: String,
            default: 'openFile'
        }

    },
    components:{
        OnlyOfficeEditor
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialog');
        },
    },
};

</script>

<style scoped>

</style>
